import React from "react"

interface AlertProps {
  show: boolean
}

const Alert: React.FC<AlertProps> = props => {
  const { show } = props

  return show ? (
    <div className="font-bold text-lg text-red-500 w-full my-2">
      <p className="text-center">Cape Fog is now available on the Appstore!</p>
    </div>
  ) : (
    <></>
  )
}

export default Alert
