import React, { useState } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { AppInfo, AppInfoGrid } from "../components/appInfo"
import {
  appInfoModels,
  topBanners,
  gridApps,
  getIconPath,
  browseAllAppsIcon,
} from "../models/models"
import { MdClose } from "react-icons/md"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Alert from "../components/alert"

const Games: React.FunctionComponent = () => {
  const [browsing, setBrowsing] = useState(false)

  return (
    <>
      <Header page={"Apps"} />
      <div className="w-full bg-gray-600 text-gray-300 p-3 flex flex-col items-center justify-center">
        <div className="w-full flex justify-between items-center bg-gray-600 text-gray-300">
          <div></div>
          <button
            onClick={() => setBrowsing(!browsing)}
            className="focus:outline-none flex items-center transform transition duration-500 ease-in-out hover:scale-105"
          >
            <img className="w-16 h-16" src={browseAllAppsIcon} alt={"apps"} />
            <p className="font-bold text-xl ml-4">Browse all apps</p>
          </button>
          <div>
            {browsing && (
              <div
                onClick={() => setBrowsing(false)}
                className="cursor-pointer p-2 bg-gray-400 rounded-full text-gray-800"
              >
                <MdClose />
              </div>
            )}
          </div>
        </div>
        {browsing && (
          <div className="py-8 px-2 grid grid-flow-row grid-cols-5 md:grid-cols-8 lg:grid-cols-9 gap-6 justify-center">
            {appInfoModels.map((l, i) => (
              <div
                key={i}
                onClick={() => navigate(`/app/${l.tag}`)}
                className="cursor-pointer flex flex-col items-center justify-center transform transition duration-300 ease-in-out hover:-translate-y-2"
              >
                <img alt={l.title} src={getIconPath(l.src)} />
                <p className="w-full text-center text-xs truncate">{l.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Layout>
        <Alert show />
        <div className="p-3 md:p-10 flex flex-col items-center">
          {topBanners.map((c, i) => (
            <div key={i} className="w-full py-2">
              <AppInfo {...c} isLanding={false} />
            </div>
          ))}
        </div>
        <div className="p-3 md:p-10 grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4">
          {gridApps.map((c, i) => (
            <div key={i}>
              <AppInfoGrid {...c} isLanding={false} />
            </div>
          ))}
        </div>
      </Layout>
      <Footer />
    </>
  )
}

export default Games
